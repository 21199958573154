import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React from "react";
import { Pie } from "react-chartjs-2";
// import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: ["Manage Market Solution", "Ameritec JSC", "Developers and Advisors", "Burn", "Walking Reward"],
  datasets: [
    {
      label: "Percent",
      data: [30, 10, 10, 25, 25],
      backgroundColor: [
        "rgb(220, 210, 103)",
        "rgb(103, 183, 220)",
        "rgb(126, 101, 216)",
        "rgb(220, 103, 206)",
        "rgb(220, 105, 103)",
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  hoverOffset: window.innerWidth <= 576 ? 30 : 60,
  layout: {
    padding: 30,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "#fff",
      titleColor: "#000",
      titleFont: {
        size: window.innerWidth <= 576 ? 13 : 16,
      },
      bodyColor: "#000",
      bodyFont: {
        size: window.innerWidth <= 576 ? 13 : 16,
      },
      callbacks: {
        label: function (context) {
          return context.parsed + "%";
        },
      },
    },
  },
};

export default function PieChart() {
  return <Pie data={data} options={options} />;
}
