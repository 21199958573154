import { useLocation } from "react-router-dom";

export const useSearchParams = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  return searchParams.get("e") || "";
};

export const useSearchParamsRef = () => {
  // lấy ref mã giới thiệu
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  return searchParams.get("ref") || "";
};
