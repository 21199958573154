import { Drawer } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import WalletConnect from "../WalletConnect/WalletConnect";

export default function Header({ titleComponent, isUsedOnHomePage }) {
  // drawer
  const [open, setOpen] = useState(true);
  const showDrawer = () => setOpen(true);
  const onClose = () => setOpen(false);
  const closeDrawer = () => setOpen(false);
  const dispatch = useDispatch();
  const isLogin = !!localStorage.getItem("user") && !!localStorage.getItem("token");

  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    dispatch({
      type: "USER_LOGOUT",
    });
  };

  return (
    <div className={isUsedOnHomePage ? "home-header" : "home-header-on-other-page"}>
      {window.innerWidth <= 992 ? (
        <div className="home-menu-mobile">
          <div className="container">
            <div className="drawer">
              <i className="fa-solid fa-bars" style={{ cursor: "pointer" }} onClick={() => showDrawer()}></i>

              <Drawer
                width={250}
                placement="right"
                onClose={onClose}
                open={open}
                closable={false}
                className="homeDrawer"
              >
                <div className="menu">
                  <ul>
                    <li>
                      <a href="/#our-journey" onClick={() => closeDrawer()}>
                        Our journey
                      </a>
                    </li>

                    <li>
                      <a href="/#howtoplay" onClick={() => closeDrawer()}>
                        How to earn HEWE
                      </a>
                    </li>

                    <li>
                      <a href="/#roadmap" onClick={() => closeDrawer()}>
                        Roadmap
                      </a>
                    </li>

                    <li>
                      <a
                        onClick={() => {
                          closeDrawer();
                          history.push("/news");
                        }}
                      >
                        News
                      </a>
                    </li>

                    <li>
                      <a href="/#team" onClick={() => closeDrawer()}>
                        Our team
                      </a>
                    </li>

                    <li>
                      <a href="/whitepaper.pdf">Whitepaper</a>
                    </li>

                    {/* <li>
                      <a
                        onClick={() => {
                          closeDrawer();
                          history.push("/token");
                        }}
                      >
                        Buy Token
                      </a>
                    </li> */}
                    {/* <li>
                      <a
                        onClick={() => {
                          closeDrawer();
                          history.push("/swap");
                        }}
                      >
                        Swap
                      </a>
                    </li> */}
                  </ul>
                </div>

                <div className="action-mobile">
                  <WalletConnect />
                  {!isLogin ? (
                    <button onClick={() => history.push("/login")}>Login / Sign up</button>
                  ) : (
                    <button onClick={handleLogout}>Logout</button>
                  )}
                </div>
              </Drawer>
            </div>

            <div className="logo" onClick={() => history.push("/")} style={{ cursor: "pointer" }}>
              <img src="/img/logo.svg" alt="Logo" />
            </div>

            <div className="drawer" style={{ visibility: "hidden" }}>
              <i className="fa-solid fa-bars"></i>
            </div>
          </div>
        </div>
      ) : (
        <div className="home-menu">
          <div className="container">
            <div className="logo" onClick={() => history.push("/")} style={{ cursor: "pointer" }}>
              <img src="/img/logo.svg" alt="Logo" />
            </div>

            <div className="menu">
              <ul>
                <li>
                  <a href="/#our-journey">Our journey</a>
                </li>

                <li>
                  <a href="/#howtoplay">How to earn HEWE</a>
                </li>

                <li>
                  <a href="/#roadmap">Roadmap</a>
                </li>

                <li>
                  <a onClick={() => history.push("/news")}>News</a>
                </li>

                <li>
                  <a href="/#team">Our team</a>
                </li>

                <li>
                  {/* <a
                    onClick={() =>
                      window.open(`${DOMAIN}whitepaper.pdf`, "_blank")
                    }
                  >
                    Whitepaper
                  </a> */}
                  <a href="/whitepaper.pdf">Whitepaper</a>
                </li>

                {/* <li>
                  <a onClick={() => history.push("/token")}>Buy Token</a>
                </li> */}
                {/* <li>
                  <a onClick={() => history.push("/swap")}>Swap</a>
                </li> */}
              </ul>
            </div>

            <div className="action">
              <WalletConnect />
              {!isLogin ? (
                <button onClick={() => history.push("/login")}>Login / Sign up</button>
              ) : (
                <button onClick={handleLogout}>Logout</button>
              )}
            </div>
          </div>
        </div>
      )}

      {titleComponent ? titleComponent : null}
    </div>
  );
}
