import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import reduxThunk from "redux-thunk";
import { sidebarReducer } from "./reducers/sidebarReducer";
import { userReducer } from "./reducers/userReducer";
import { walletReducer } from "./reducers/walletReducer";

const rootReducer = combineReducers({ sidebarReducer, userReducer, walletReducer });

const middleWare = applyMiddleware(reduxThunk);
const composeCustom = compose(middleWare);

const store = createStore(rootReducer, composeCustom);

export default store;
