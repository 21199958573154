export const heweChain56 = {
  id: 0x38,
  name: "Smart Chain",
  network: "0x38",
  nativeCurrency: {
    decimals: 18,
    name: "Smart Chain",
    symbol: "BNB",
  },
  rpcUrls: {
    public: { http: ["https://bsc-dataseed.binance.org/"] },
    default: { http: ["https://bsc-dataseed.binance.org/"] },
  },
};
