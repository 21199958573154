import { Button, Table, Tag, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useModal } from "../../hooks/useModal";
import { axiosService } from "../../util/service";
import { Input } from "../Input/Input";
import { Modal } from "../Modal/Modal";

const LIMIT = 10;

export const HistoryRefToken = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [isPendingGetHistory, setIsPendingGetHistory] = useState(false);
  const { isOpen, handleCloseModal, handleOpenModal } = useModal();
  const {
    isOpen: isOpenReject,
    handleCloseModal: handleCloseModalReject,
    handleOpenModal: handleOpenModalReject,
  } = useModal();
  const [currentHistoryFocus, setCurrentHistoryFocus] = useState(null);
  const [isPendingApprove, setIsPendingApprove] = useState(false);
  const [isPendingReject, setIsPendingReject] = useState(false);
  const [hash, setHash] = useState("");
  const hashInputRef = useRef(null);

  const handleChangeHash = (value) => {
    setHash(value.target.value.trim());
  };

  const handleCloseModalMdw = () => {
    handleCloseModal();
    setHash("");
    setCurrentHistoryFocus(null);
    setIsPendingApprove(false);
  };

  const handleCloseModalRejectMdw = () => {
    handleCloseModalReject();
    setCurrentHistoryFocus(null);
    setIsPendingReject(false);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleGetHistory = async ({ page, limit = LIMIT }) => {
    await axiosService
      .post("api/user/historyRefTokenAdmin", {
        page,
        limit,
      })
      .then((res) => {
        setData(res.data.data.array);
        setTotalItem(res.data.data.total);
      });
  };

  const handleClickApproval = (id) => () => {
    setCurrentHistoryFocus(id);
    handleOpenModal();
  };

  const handleClickReject = (id) => () => {
    setCurrentHistoryFocus(id);
    handleOpenModalReject();
  };

  const handleRequestApprove = async () => {
    setIsPendingApprove(true);

    try {
      const res = await axiosService.post("api/user/comfirmRefTokenAdmin", {
        id: currentHistoryFocus,
        hash,
      });

      message.success(res.data.message);
      handleCloseModal();
      setCurrentHistoryFocus(null);
      setHash("");
      setIsPendingApprove(false);
      handleGetHistory({ page: currentPage });
    } catch (error) {
      message.error(error.response.data.message);
      setIsPendingApprove(false);
    }
  };

  const handleRequestReject = async () => {
    setIsPendingReject(true);

    try {
      const res = await axiosService.post("api/user/cancelRefTokenAdmin", {
        id: currentHistoryFocus,
      });

      message.success(res.data.message);
      handleCloseModalReject();
      setCurrentHistoryFocus(null);
      setIsPendingReject(false);
      handleGetHistory({ page: currentPage });
    } catch (error) {
      message.error(error.response.data.message);
      setIsPendingReject(false);
    }
  };

  const hanldeCopy = (value) => () => {
    navigator.clipboard.writeText(value.toString());
    message.success("Copy success");

    return true;
  };

  const columns = [
    { title: "Amount", dataIndex: "amount" },
    {
      title: "Commission",
      dataIndex: "percent",
      render: (_, item) => {
        return item.amount * item.percent;
      },
    },
    {
      title: "Hash",
      dataIndex: "hash",
      render: (value) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              width: "300px",
              wordBreak: "break-all",
            }}
          >
            <a href={`https://bscscan.com/tx/${value}`} target="_blank" rel="noreferrer">
              {value}
            </a>
            <i onClick={hanldeCopy(value)} className="fa-solid fa-copy" style={{ cursor: "pointer" }}></i>
          </div>
        );
      },
    },
    {
      title: "From address",
      dataIndex: "fromAddress",
      render: (value) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              width: "300px",
              wordBreak: "break-all",
            }}
          >
            {value}
            <i onClick={hanldeCopy(value)} className="fa-solid fa-copy" style={{ cursor: "pointer" }}></i>
          </div>
        );
      },
    },
    {
      title: "Address ref",
      dataIndex: "addressRef",
      render: (value) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              width: "300px",
              wordBreak: "break-all",
            }}
          >
            {value}
            <i onClick={hanldeCopy(value)} className="fa-solid fa-copy" style={{ cursor: "pointer" }}></i>
          </div>
        );
      },
    },
    {
      title: "Hash confirm",
      dataIndex: "hashConfirm",
      render: (value) => {
        return value || "-";
      },
    },
    { title: "Time", dataIndex: "created_at" },
    {
      title: "Status",
      render: (_, record) => {
        if (record.status == 0) return <Tag color="red">CANCELLED</Tag>;
        else if (record.status == 1) return <Tag color="green">SUCCESS</Tag>;
        else if (record.status == 2) return <Tag color="orange">PENDING</Tag>;
      },
    },
    {
      width: 120,
      render: (_, record) => {
        if (record.status == 2) {
          return (
            <div style={{ display: "flex", gap: "8px" }}>
              <Button type="primary" size="small" onClick={handleClickApproval(record.id)}>
                Confirm
              </Button>
              <Button type="danger" size="small" onClick={handleClickReject(record.id)}>
                Cancel
              </Button>
            </div>
          );
        } else return <></>;
      },
    },
  ];

  useEffect(() => {
    handleGetHistory({ page: currentPage });
  }, [currentPage]);

  return (
    <div className="banksContainer">
      <div className="header">
        <h2 className="titleContainer">History ref token</h2>
      </div>

      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={data}
        scroll={{ x: 1000 }}
        pagination={{
          pageSize: LIMIT,
          total: totalItem,
          current: currentPage,
          onChange: handleChangePage,
          showSizeChanger: false,
          showQuickJumper: false,
          showLessItems: false,
        }}
      />

      <Modal
        title="Confirm transaction"
        isOpen={isOpen}
        confirmLoading={isPendingApprove}
        onCancel={handleCloseModalMdw}
        onConfirm={handleRequestApprove}
        okText="Confirm"
      >
        <Input isFullWidth={true} value={hash} onChange={handleChangeHash} label="Hash" ref={hashInputRef} />
      </Modal>

      <Modal
        title="Cancel transaction"
        isOpen={isOpenReject}
        confirmLoading={isPendingReject}
        onCancel={handleCloseModalRejectMdw}
        onConfirm={handleRequestReject}
        okText="Confirm"
        isDangerButton={true}
      >
        Are you sure to cancel this transaction?
      </Modal>
    </div>
  );
};
