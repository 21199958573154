import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Web3Modal, useWeb3ModalTheme } from "@web3modal/react";
import { useEffect } from "react";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { heweChain56 } from "../../data/myChain56.js";
import { heweChain97 } from "../../data/myChain97.js";
import { network } from "../../data/port.js";
import Account from "./Account.jsx";

const chains = network == "97" ? [heweChain97] : [heweChain56];
const defaultChain = network == "97" ? heweChain97 : heweChain56;

const projectId = "6ee3a3995bd809d80e5626f87fbb45ae";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);

export default function WalletConnect() {
  const { _, setTheme } = useWeb3ModalTheme();

  useEffect(() => {
    const webCurrentTheme = "light";

    setTheme({
      themeMode: webCurrentTheme,
      themeVariables: {
        "--w3m-font-family": "Source Sans 3",
        "--w3m-overlay-background-color": "rgba(0, 0, 0, 0.8)",
        "--w3m-overlay-backdrop-filter": "blur(2px)",
        "--w3m-accent-color": "#154e24",
        "--w3m-background-color": "#154e24",
        "--w3m-logo-image-url": "https://hewe.club/img/logo.svg",
      },
    });
  }, []);

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <Account />
      </WagmiConfig>

      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} defaultChain={defaultChain} />
    </>
  );
}
