import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

export default function PrivacyPage({ history }) {
  // drawer
  const [open, setOpen] = useState(false);
  const showDrawer = () => setOpen(true);
  const onClose = () => setOpen(false);
  const closeDrawer = () => setOpen(false);

  // top button
  const [showButton, setShowButton] = useState(false);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollTop();

    window.onscroll = () => {
      if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
  }, []);

  return (
    <div className="privacy-page">
      <div className="privacy-header">
        <div className="overlay"></div>

        {window.innerWidth <= 992 ? (
          <div className="home-menu-mobile">
            <div className="container">
              <div className="logo" onClick={() => history.push("/")} style={{ cursor: "pointer" }}>
                <img src="/img/logo.svg" alt="Logo" />
              </div>
            </div>
          </div>
        ) : (
          <div className="home-menu">
            <div className="container">
              <div className="logo" onClick={() => history.push("/")} style={{ cursor: "pointer" }}>
                <img src="/img/logo.svg" alt="Logo" />
              </div>
            </div>
          </div>
        )}

        <div className="privacy">
          <div className="container">
            <h2 className="title">Privacy Policy</h2>

            <p>
              May 8th, 2022 Welcome! Thanks for visiting Ameritec JSC (Hewe), a digital asset platform. Use of the words
              “Ameritec JSC (Hewe),” “we,” “us,” or “our” refer to any or all Ameritec JSC (Hewe) entities and their
              affiliates. This Privacy Policy explains what Personal Information (as defined below) we collect, why we
              collect it, how we use and disclose it, your choices, any rights you may have, and how you can contact us
              about our privacy practices. By continuing to use Ameritec JSC (Hewe), you agree to this Privacy Policy.
              This Privacy Policy does not apply to third-party websites, products, or services, even if we provide
              links to them or they link to us. Your privacy matters to us so whether you are new to Ameritec JSC (Hewe)
              or a long-time customer, please do take the time to get to know and familiarize yourself with our policies
              and practices. If you are an employee, contractor or job applicant, please refer to our separate Applicant
              and Employee Privacy Policy. Feel free to print and keep a copy of this Privacy Policy, but please
              understand that we reserve the right to change any of our policies and practices at any time. But don’t
              worry, you can always find the latest version of this Privacy Policy here on this page. This table
              describes which Ameritec JSC (Hewe) entity or entities you are engaging with, which determines the means
              and purposes of processing your Personal Information: Depending on what Ameritec JSC (Hewe) products and
              services you use, we may provide you with an additional consumer privacy notice detailing how we will
              collect, use, and share your Personal Information for that product or service (each a “Ameritec JSC (Hewe)
              Consumer Privacy Notice”). In the event of any conflict between this Privacy Policy and any Ameritec JSC
              (Hewe) Consumer Privacy Notice, the Ameritec JSC (Hewe) Consumer Privacy Notice will control. If you are a
              retail Ameritec JSC (Hewe) customer or a Ameritec JSC (Hewe) Credit Card customer, please find your
              Ameritec JSC (Hewe) Consumer Privacy Notice. 1. Personal Information We Collect Personal information
              typically means information that identifies or is reasonably capable of identifying an individual,
              directly or indirectly, and information that relates to, describes, is reasonably capable of being
              associated with or could reasonably be linked to an identified or reasonably identifiable individual. For
              the purposes of this Privacy Policy, only the definition of personal information from the applicable law
              of your legal residence will apply to you and be deemed your “Personal Information.” A. Personal
              Information we collect from you We may collect the following categories of Personal Information directly
              from you: Identification Information, such as name, email, phone number, postal address, government
              identification numbers (which may include Social Security Number or equivalent, driver’s license number,
              passport number); Commercial Information, such as trading activity, order activity, deposits, withdrawals,
              account balances; Financial Information, such as bank account information, routing number; Correspondence,
              such as information that you provide to us in correspondence, including account opening and customer
              support; Sensory Information, such as images collected for identity verification, audio recordings left on
              answering machines; Employment Information, such as job title, source of wealth; and Institutional
              Information, such as for institutional customers, we may collect additional information, including:
              institution’s legal name, Employer Identification Number (“EIN”) or any comparable identification number
              issued by a government, and proof of legal existence (which may include articles of incorporation,
              certificate of formation, business license, trust instrument, or other comparable legal document). B.
              Personal Information we collect automatically We may collect the following categories of Personal
              Information automatically through your use of our services: Online Identifiers, such as IP address; domain
              name; Device Information, such as hardware, operating system, browser; and Usage Data, such as system
              activity, internal and external information related to Ameritec JSC (Hewe) pages that you visit,
              clickstream information Our automatic collection of Personal Information may involve the use of Cookies,
              described in greater detail below. C. Personal Information we collect from third parties We may collect
              and/or verify the following categories of Personal Information about you from Third Parties:
              Identification Information, such as name, email, phone number, postal address, government identification
              numbers (which may include Social Security Number or equivalent, driver’s license number, passport
              number); Financial Information, such as bank account information, routing number; Transaction Information,
              such as public blockchain data (bitcoin, ether, and other Digital Assets are not truly anonymous. We, and
              any others who can match your public Digital Asset address to other Personal Information about you, may be
              able to identify you from a blockchain transaction because, in some circumstances, Personal Information
              published on a blockchain (such as your Digital Asset address and IP address) can be correlated with
              Personal Information that we and others may have. Furthermore, by using data analysis techniques on a
              given blockchain, it may be possible to identify other Personal Information about you); Credit and Fraud
              Information, such as credit investigation, credit eligibility, identity or account verification, fraud
              detection, or as may otherwise be required by applicable law; and Additional Information, as permitted by
              law or required to comply with legal obligations, which may include criminal records or alleged criminal
              activity, or information about any person or corporation with whom you have had, currently have, or may
              have a financial relationship. Personal Information you provide during the registration process may be
              retained, even if your registration is left incomplete or abandoned. 2. How We Use Your Personal
              Information The Personal Information we collect and the practices described above are done in an effort to
              provide you with the best experience possible, protect you from risks related to improper use and fraud,
              and help us maintain and improve Ameritec JSC (Hewe). We may use your Personal Information to: A. Provide
              you with our services. We use your Personal Information to provide you with our services pursuant to the
              terms of our User Agreement. For example, in order to facilitate fiat transfers into and out of your
              account, we need to know your financial information. B. Comply with legal and regulatory requirements. We
              process your Personal Information as required by applicable laws and regulations. For example, we have
              identity verification requirements to fulfill our obligations under the anti-money laundering laws of
              numerous jurisdictions. C. Detect and prevent fraud. We process your Personal Information to detect and
              prevent fraud on your account, which is especially important given the irreversible nature of
              cryptocurrency transactions. D. Protect the security and integrity of our services. We use your Personal
              Information to further our security-first mentality. Maintaining the security of your account and the
              Exchange requires us to process your Personal Information, including information about your device and
              your activity on the Exchange, and other relevant information. E. Provide you with customer support. We
              process your Personal Information anytime that you reach out to our Customer Support team, such as when
              there are issues arising from your account. F. Optimize and enhance our services. We use your Personal
              Information to understand how our products and Services are being used to help us improve our Services and
              develop new products. G. Market our products to you. We may contact you with information about our
              products and services that we believe may be of interest to you. You may use tools that we provide to opt
              out of marketing communications from us at any time. H. With your consent. We may use your Personal
              Information for additional purposes with your consent. I. Other business purposes. We may use your
              Personal Information for additional purposes in the operation of our business, that would be reasonably
              expected based on context, and as permitted by law or required to comply with our legal obligations. 3.
              How We Share Your Personal Information We will not share your Personal Information with third parties,
              except as described below: A. Service Providers. We may share your Personal Information with third-party
              service providers for business or commercial purposes. Your Personal Information may be shared so that
              they can provide us with services, including identity verification, fraud detection and prevention, credit
              verification, security threat detection, payment processing, customer support, data analytics, Information
              Technology, advertising, marketing, data processing, network infrastructure, storage, transaction
              monitoring, and tax reporting. We share your Personal Information with these service providers only so
              that they can provide us with services, and we prohibit our service providers from using or disclosing
              your Personal Information for any other purpose. Our third-party service providers are subject to strict
              confidentiality obligations. B. Affiliates. We may share your Personal Information with our affiliates,
              for the purposes outlined above, and as it is necessary to provide you with our Services. C. Legal
              Obligations. We may be required to share your Personal Information with law enforcement, government
              agencies, regulators or third parties to comply with legal or regulatory requirements. D. Corporate
              Transactions. We may disclose Personal Information in the event of a proposed or consummated merger,
              acquisition, reorganization, asset sale, or similar corporate transaction, or in the event of a bankruptcy
              or dissolution. E. Professional Advisors. We may share your Personal Information with our professional
              advisors, including legal, accounting, or other consulting services for purposes of audits or to comply
              with our legal obligations. F. Consent. We may share or disclose your information with your consent. G.
              Other business purposes. We may share or disclose your Personal Information for other business purposes as
              permitted by law or required to comply with legal obligations. If we decide to modify the purposes for
              which your Personal Information is collected, used, or shared, or our practices relating to your Personal
              Information, we will amend this Privacy Policy as described below. 4. Cookies When you access Ameritec JSC
              (Hewe), we may make use of the standard practice of placing tiny data files called cookies, flash cookies,
              pixel tags, or other tracking tools (herein, “Cookies”) on your computer or other devices used to visit
              Ameritec JSC (Hewe). We use Cookies to help us recognize you as a customer, collect information about your
              use of Ameritec JSC (Hewe) to better customize our services and content for you, and collect information
              about your computer or other access devices to: (i) ensure compliance with our Bank Secrecy Act (“BSA”)
              and anti-money laundering (“AML”) program (“BSA/AML Program”) (ii) ensure that your account security has
              not been compromised by detecting irregular, suspicious, or potentially fraudulent account activities;
              (iii) assess and improve our services and advertising campaigns. We may make use of third-party Cookies or
              analytics services, including but not limited to Google Analytics, Facebook, and MixPanel. To learn more
              about Google Analytics and how to opt-out of making your activities available to Google Analytics, please
              visit https://marketingplatform.google.com/about/ or
              https://support.google.com/analytics/answer/181881?hl=en. To learn more about how Facebook uses your data
              please visit https://www.facebook.com/help/325807937506242/ or log on to your Facebook account and access
              your settings. To understand more about Facebook advertising please see here
              https://www.facebook.com/about/ads. To learn more about MixPanel, please visit
              https://mixpanel.com/legal/privacy-policy/. Third-party tracking technologies are not controlled by us,
              and statements regarding our practices do not apply to these third-parties or their use of information. We
              make no representations regarding the policies or practices of such third parties. You may be able to opt
              out of the practices of some of these third parties if they are members of the Network Advertising
              Initiative (“NAI”) by visiting http://www.networkadvertising.org/choices or if they participate in the
              Digital Advertising Alliance (“DAA”) by visiting http://www.aboutads.info/choices. We are not responsible
              for effectiveness of or compliance with any third-parties’ opt-out options. We do not respond to browser
              “Do Not Track” signals. which includes additional useful information on cookies and how to block cookies
              on different types of browsers and mobile devices. Please note that if you reject cookies, you will not be
              able to use some or all of Ameritec JSC (Hewe). If you do not consent to the placing of Cookies on your
              device, please do not visit, access, or use Ameritec JSC (Hewe). 5. Direct Marketing Subject to applicable
              laws and regulations, we may from time to time send direct marketing materials promoting services,
              products, facilities, or activities to you using information collected from or about you. You may opt-out
              of such marketing communications at any time by visiting the Exchange tab of your Account Setting page.
              You may also opt-out of such communications by following the directions provided in any marketing
              communication. It is our policy to not provide your Personal Information to third parties for their own
              direct marketing purposes without your consent. If you opt out of marketing communications, please note
              that we may still send you necessary information about transactions or our business relationship. 6.
              Children’s Personal Information Ameritec JSC (Hewe) is not intended for children under the age of 18 and
              we do not knowingly collect Personal Information of children under the age of 18. If we learn that we have
              collected any Personal Information from a child under the age of 18, we will promptly delete it from our
              systems. 7. Information Security We take reasonable measures, including administrative, technical, and
              physical safeguards, to protect your Personal Information from loss, theft, or misuse, and from
              unauthorized access, disclosure, alteration, and destruction. Nevertheless, no security is foolproof, and
              the Internet is an insecure medium. We cannot guarantee absolute security, but we work hard to protect
              Ameritec JSC (Hewe) and you from unauthorized access to or unauthorized alteration, disclosure, or
              destruction of Personal Information we collect and store. Measures we take may include encryption of the
              Ameritec JSC (Hewe) website communications with SSL; required two-factor authentication for all sessions;
              periodic review of our Personal Information collection, storage, and processing practices; and restricted
              access to your Personal Information on a need-to-know basis for our employees, contractors and agents who
              are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they
              fail to meet these obligations. 8. Retention We retain your Personal Information for as long as is
              reasonably necessary to provide services to you, for our legitimate business purposes, and to comply with
              our legal and regulatory obligations. If you close your account with us, we will continue to retain your
              Personal Information as necessary to comply with our legal and regulatory obligations, including for fraud
              monitoring, detection and prevention; and for our tax, accounting, and financial reporting obligations. 9.
              International Transfers Ameritec JSC (Hewe) is a global business. As a result, Personal Information may be
              stored and processed in any country where we have operations or where we engage service providers. We may
              transfer Personal Information that we maintain about you to recipients in countries other than the country
              in which the Personal Information was originally collected. Those other countries may have data protection
              or privacy rules that are different from those of your country. However, we will take measures to ensure
              that any such transfers comply with applicable data protection laws and that your Personal Information
              remains protected to the standards described in this Privacy Policy. In certain circumstances, courts, law
              enforcement agencies, regulatory agencies or security authorities in those other countries may be entitled
              to access your Personal Information. If you are located in the European Economic Area (“EEA”), the UK or
              Switzerland, engaging with Ameritec JSC (Hewe) Europe, Ltd or Ameritec JSC (Hewe) Services, Ltd, we comply
              with applicable law to provide an adequate level of data protection for the transfer of your Personal
              Information to the US or other third countries. Ameritec JSC (Hewe) enters into the appropriate data
              processing agreements and, if required, standard contractual clauses for the transfer of data which have
              been approved by the applicable data protection regulatory authority. We may transfer personal data from
              Europe to third countries outside of Europe, including the United States, under the following conditions:
              Contractual obligation. Where transfers are necessary to satisfy our obligation to you under our User
              Agreement, including to provide you with our services and customer support services, and to optimize and
              enhance Ameritec JSC (Hewe); and Consent. Where you have consented to the transfer of your personal data
              to a third country. Where transfers to a third country are based on your consent, you may withdraw your
              consent at any time. Please understand, however, that our services may not be available if we are unable
              to transfer personal data to third countries. 10. Information For California Residents We are required
              under the California Consumer Privacy Act of 2018 (“CCPA”) to provide certain information to California
              residents about how we collect, use and share their personal information, and about the rights and choices
              California residents may have concerning their personal information. For purposes of this section,
              “personal information” has the meaning provided in the CCPA. Personal information that we collect, use,
              and share For ease of reference, we have compiled the chart below to demonstrate how we use the categories
              of personal information we have collected, used, and disclosed in the past 12 months. We do not “sell” any
              personal information, as “selling” is defined under the CCPA. CATEGORY OF PERSONAL INFORMATION SOURCE(S)
              OF PERSONAL INFORMATION WHY WE COLLECT YOUR PERSONAL INFORMATION HOW WE SHARE YOUR PERSONAL INFORMATION
              Identifiers such as Personal Identification Information Sections 1.A, 1.B, 1.C Sections 2.A, 2.B, 2.C,
              2.D, 2.E, 2.F, 2.G, 2.H, 2.I Sections 3.A, 3.B, 3.C, 3.D, 3.E, 3.F, 3.G Commercial information such as
              records of products or services purchased, obtained, or considered Sections 1.A, 1.C Sections 2.A, 2.B,
              2.C, 2.E, 2.F, 2.H, 2.I Sections 3.A, 3.B, 3.C, 3.D, 3.E, 3.F, 3.G Internet or other electronic network
              activity information, including browsing history and search history Section 1.B Sections 2.A, 2.B, 2.C,
              2.E, 2.F, 2.H, 2.I Sections 3.A, 3.B, 3.C, 3.D, 3.E, 3.F, 3.G Usage Data Section 1.B Sections 2.C, 2.D,
              2.F, 2.H, 2.I Sections 3.A, 3.B, 3.C, 3.D, 3.E, 3.F, 3.G Sensory data, such as audio, electronic, visual,
              or other similar information Section 1.A Sections 2.A, 2.E, 2.H, 2.I Sections 3.A, 3.B, 3.C, 3.D, 3.E,
              3.F, 3.G Professional or employment-related information Section 1.A, 1.C Sections 2.A, 2.B, 2.C, 2.H, 2.I
              Sections 3.A, 3.B, 3.C, 3.D, 3.E, 3.F, 3.G Inferences about preferences, characteristics, psychological
              trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes Sections 1.A, 1.B,
              1.C Sections 2.A, 2.C, 2.F, 2.G, 2.H, 2.I Sections 3.A, 3.B, 3.C, 3.D, 3.E, 3.F, 3.G California privacy
              rights California consumers have the following additional rights under the CCPA with respect to their
              Personal Information. Please understand, however, that these rights are not absolute, and we may decline
              requests as permitted under applicable law. Right to Request Access. You may submit a request that
              Ameritec JSC (Hewe) disclose the categories of personal information we have collected about you, the
              categories of sources from which your personal information is collected, the business or commercial
              purpose for collecting your personal information, the categories of third parties with whom we share your
              personal information, and the specific pieces of personal information we have collected about you in the
              past 12 month. Right to Request Deletion. You may submit a request that Ameritec JSC (Hewe) delete the
              personal information about you that we have collected from you. Right Not to Receive Discriminatory
              Treatment. You have the right to exercise the privacy rights conferred by the CCPA without discriminatory
              treatment. You may submit a request to access or delete your personal information by following the
              “Exercise your privacy rights” link on your Account Profile page or by emailing us at
              privacy@hewetoken.io. We recommend that you submit your request through the Account Settings page because
              it is a more secure method than email. We are legally obligated to verify your identity when you submit a
              request. We may request additional information from you, like your date of birth or a personal
              identification number, to verify your identity. If we are unable to verify your identity, we may deny your
              request. If you submit a request to delete your personal information, you will also be required to confirm
              the deletion request by email. You may designate an authorized agent to make a request under the CCPA on
              your behalf. Your authorized agent must submit proof that you have provided them with power of attorney
              pursuant to Probate Code sections 4000 to 4465. We may deny a request from a purported authorized agent
              who does not provide proof of authorization to act on your behalf. Please understand that we are not
              obligated to provide you with access to your personal information more than twice in a 12-month period. If
              your request is manifestly unfounded or excessive, we may either charge a reasonable fee or refuse to act
              on the request in which case we will notify you of the reason for refusing the request. 11. Information
              For Persons Subject To UK Data Protection Laws If you are located in the EEA or the UK and engaging with
              Ameritec JSC (Hewe), we adhere to relevant UK data protection laws, and provide individuals with the
              following additional information. For purposes of this section, “personal data” has the meaning provided
              in the General Data Protection Regulation (EU) 2016/679 as it forms part of the law of England and Wales,
              Scotland and Northern Ireland by virtue of section 3 of the European Union (Withdrawal) Act 2018 (“GDPR”).
              Lawful bases for processing We process personal data subject to GDPR on one or more of the following legal
              bases: Legal Obligation: to conduct anti-fraud and identity verification and authentication checks and to
              fulfill our retention obligations; Contractual Obligation: to satisfy our obligations to you under our
              User Agreement, including to provide you with our services and customer support services, and to optimize
              and enhance Ameritec JSC (Hewe); Legitimate Interest: to monitor the usage of Ameritec JSC (Hewe), conduct
              automated and manual security checks of our service, to protect our rights; and Consent: to market
              Ameritec JSC (Hewe) and our services to you. You may withdraw your consent at any time without affecting
              the lawfulness of processing based on consent before consent is withdrawn. European privacy rights
              European Residents have the following rights under GDPR, subject to certain exceptions provided under the
              law, with respect to their personal data: Rights to Access and Rectification. You may submit a request
              that Ameritec JSC (Hewe) disclose the personal data that we process about you and correct any inaccurate
              personal data. Right to Erasure. You may submit a request that Ameritec JSC (Hewe) delete the personal
              data that we have about you. Right to Restriction of Processing and Right to Object. You have the right to
              restrict or object to our processing of your personal data under certain circumstances. Right to Data
              Portability. You have the right to receive the personal data you have provided to us in an electronic
              format and to transmit that Personal Information to another data controller. When handling requests to
              exercise European privacy rights, we check the identity of the requesting party to ensure that he or she
              is the person legally entitled to make such request. While we maintain a policy to respond to these
              requests free of charge, should your request be repetitive or unduly onerous, we reserve the right to
              charge you a reasonable fee for compliance with your request. Automated decision-making We may engage in
              automated decision-making for purposes of fraud detection and prevention. When we do, we implement
              suitable measures to safeguard your rights and freedoms and legitimate interests, including the right to
              obtain human intervention, to express your point of view and to contest the decision. 12. Updates To this
              Privacy Policy We may change this Privacy Policy from time to time to reflect changes to our privacy
              practices, enhance user experience, or comply with relevant laws. The “Last updated” legend at the top of
              this Privacy Policy indicates when this Privacy Policy was last revised. Any changes are effective when we
              post the revised Privacy Policy. Your continued use of Ameritec JSC (Hewe) after our posting of changes to
              this Privacy Policy means that you understand and agree to such changes.
            </p>
          </div>
        </div>
      </div>

      <div className="privacy-footer">
        <div className="container">
          <div className="item item1">
            <img src="/img/logo.svg" />
          </div>

          <div className="item item3">
            <NavLink to={"/privacy"}>Privacy Policy</NavLink>
            <NavLink to={"/term"}>Terms and Conditions</NavLink>
          </div>

          <div className="item item4">
            <a href="https://www.facebook.com/Hewetoken" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-facebook"></i>
            </a>
            <a href="https://twitter.com/PierreN28036176" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-twitter"></i>
            </a>
          </div>
        </div>
        {window.innerWidth <= 450 ? (
          <div className="container2">
            Copyright © 2022, Health &amp; Wealth. <br /> All rights reserved.
          </div>
        ) : (
          <div className="container2">Copyright © 2022, Health &amp; Wealth. All rights reserved.</div>
        )}
      </div>

      {showButton ? (
        <div className="home-totop" onClick={scrollTop}>
          <i className="fa-solid fa-up-long"></i>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
