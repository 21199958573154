import React, { useEffect, useState } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { axiosService } from "../../util/service";

export default function Roadmap() {
  const [map, setMap] = useState([]);

  const getRoadmap = async () => {
    try {
      let response = await axiosService.post("api/news/getRoadmap");
      setMap(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoadmap();
  }, []);

  return (
    <div className="home-roadmap" id="roadmap">
      <div className="overlay1">
        <div className="overlay2">
          <div className="container">
            <h2 className="title">Roadmap</h2>

            <div className="list">
              <VerticalTimeline>
                {map.map((item, index) => {
                  return (
                    <VerticalTimelineElement
                      key={index}
                      date={item?.time}
                      contentStyle={{ background: "rgba(212, 212, 212, 0.8)", color: "#000" }}
                      iconStyle={{ background: "rgb(105, 178, 11)" }}
                      dateClassName="vted"
                    >
                      <h3 className="vertical-timeline-element-title">{item.title}</h3>
                      <p>{item.detail}</p>
                    </VerticalTimelineElement>
                  );
                })}
              </VerticalTimeline>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
