import { Button, Input } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { showAlert } from "../../function/showAlert";
import { axiosService } from "../../util/service.js";

export default function LoginPage({ history }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Required").email("Invalid email"),
      password: Yup.string().required("Required"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      login(values);
    },
  });

  const login = async (info) => {
    setLoading(true);

    try {
      let response = await axiosService.post("/api/user/login", info);
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      dispatch({
        type: "USER_LOGIN",
        payload: response.data.data,
      });
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  useEffect(() => {
    document
      .getElementById("login-form")
      ?.addEventListener("keypress", handleEnter);
  }, []);

  if (localStorage.getItem("user") && localStorage.getItem("token")) {
    const x = JSON.parse(localStorage.getItem("user"));
    if (x.id == 1 || x.type == 1) {
      return <Redirect to="/admin" />;
    } else {
      return <Redirect to="/token" />;
    }
  }

  return (
    <div className="login-page">
      <div className="login-area">
        <div className="login-logo">
          <img src="/img/logo.png" onClick={() => history.push("/home")} />
          <h2 className="title">Login</h2>
        </div>

        <form id="login-form">
          <div className="email-field field">
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
            />
            {formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="password-field field">
            <label htmlFor="password">Password</label>
            <Input.Password
              type="password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              name="password"
            />
            {formik.errors.password ? (
              <div className="error">{formik.errors.password}</div>
            ) : null}
          </div>

          <Button
            type="primary"
            style={{ width: "100%" }}
            size="large"
            onClick={formik.handleSubmit}
            loading={loading}
          >
            Login
          </Button>

          <div className="forgot-password">
            <span onClick={() => history.replace("/reset-password")}>
              Forgot password?
            </span>
          </div>

          <div className="link-to-signup">
            Need an account?
            <span onClick={() => history.replace("/signup")}>
              {" "}
              Create an account
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}
