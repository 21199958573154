import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";

import { useSelector } from "react-redux";
import AdminCheck from "./components/AdminPage/AdminCheck";
import AdminContent from "./components/AdminPage/AdminContent";
import AdminDashboard from "./components/AdminPage/AdminDashboard";
import AdminData from "./components/AdminPage/AdminData";
import AdminData2 from "./components/AdminPage/AdminData2";
import AdminKYC from "./components/AdminPage/AdminKYC";
import AdminRoadmap from "./components/AdminPage/AdminRoadmap";
import AdminSendMail from "./components/AdminPage/AdminSendMail";
import AdminTemplate from "./components/AdminPage/AdminTemplate";
import AdminTransfer from "./components/AdminPage/AdminTransfer";
import AdminUser from "./components/AdminPage/AdminUser";
import AdminWithdraw from "./components/AdminPage/AdminWithdraw";
import UserDashboard from "./components/AdminPage/UserDashboard";
import { BanksPage } from "./components/BanksPage/BanksPage";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import { HistoryBuyToken } from "./components/HistoryBuyToken/HistoryBuyToken";
import { HistoryRefToken } from "./components/HistoryRefToken/HistoryRefToken";
import HomePage from "./components/HomePage/HomePage";
import LoginPage from "./components/LoginPage/LoginPage";
import DetailPage from "./components/NewsPage/DetailPage";
import NewsPage from "./components/NewsPage/NewsPage";
import ManagePost from "./components/Post/ManagePost";
import Media from "./components/Post/Media";
import Writer from "./components/Post/Writer";
import WritterEdit from "./components/Post/WritterEdit";
import PrivacyPage from "./components/PrivacyPage/PrivacyPage";
import SignupPage from "./components/SignupPage/SignupPage";
import TermPage from "./components/TermPage/TermPage";
import TokenPage from "./components/TokenPage/TokenPage";
import { UserTemplate } from "./components/UserPage/components";
import { HistoryBuyTokenUser } from "./components/UserPage/components/HistoryBuyTokenUser/HistoryBuyTokenUser";
import WhitePaper from "./components/WhitePaper/WhitePaper";

function App() {
  const { user } = useSelector((root) => root.userReducer);

  return (
    <BrowserRouter>
      <Switch>
        <AdminTemplate path="/admin/users" component={user?.id == 1 ? AdminUser : AdminCheck} />
        <AdminTemplate path="/admin/user-network/:uid" component={user?.id == 1 ? UserDashboard : AdminCheck} />
        <AdminTemplate
          path="/admin/kyc"
          component={user?.id == 1 || (user?.type == 1 && user?.kyc == 1) ? AdminKYC : AdminCheck}
        />
        <AdminTemplate path="/admin/transfer" component={user?.id == 1 ? AdminTransfer : AdminCheck} />
        <AdminTemplate path="/admin/withdraw" component={user?.id == 1 ? AdminWithdraw : AdminCheck} />
        <AdminTemplate
          path="/admin/content/:id"
          component={user?.id == 1 || (user?.type == 1 && user?.content == 1) ? AdminContent : AdminCheck}
        />
        <AdminTemplate
          path="/admin/add-news"
          component={user?.id == 1 || (user?.type == 1 && user?.content == 1) ? Writer : AdminCheck}
        />
        <AdminTemplate
          path="/admin/manage-news"
          component={user?.id == 1 || (user?.type == 1 && user?.content == 1) ? ManagePost : AdminCheck}
        />
        <AdminTemplate
          path="/admin/edit-news/:id"
          component={user?.id == 1 || (user?.type == 1 && user?.content == 1) ? WritterEdit : AdminCheck}
        />
        <AdminTemplate
          path="/admin/images"
          component={user?.id == 1 || (user?.type == 1 && user?.content == 1) ? Media : AdminCheck}
        />
        <AdminTemplate path="/admin/roadmap" component={user?.id == 1 ? AdminRoadmap : AdminCheck} />
        <AdminTemplate path="/admin/send-email" component={user?.id == 1 ? AdminSendMail : AdminCheck} />
        <AdminTemplate path="/admin/dashboard" component={user?.id == 1 ? AdminDashboard : AdminCheck} />
        <AdminTemplate path="/admin/running-data" component={user?.id == 1 ? AdminData : AdminCheck} />
        <AdminTemplate path="/admin/reward-data" component={user?.id == 1 ? AdminData2 : AdminCheck} />
        {/* <AdminTemplate path="/admin/banks" component={BanksPage} /> */}
        {/* <AdminTemplate path="/admin/history-buy-token" component={HistoryBuyToken} /> */}
        <AdminTemplate path="/admin/history-ref-token" component={HistoryRefToken} />
        <AdminTemplate path="/admin" component={AdminCheck} />

        {/* <UserTemplate
          path="/user/buy-token"
          component={user?.id !== 1 ? BuyToken : AdminCheck}
        /> */}
        {/* <UserTemplate path="/user/history-buy-token" component={user?.id !== 1 ? HistoryBuyTokenUser : AdminCheck} /> */}
        <UserTemplate path="/user" component={AdminCheck} />

        <Route exact path={"/home"} component={HomePage} />
        <Route exact path={"/login"} component={LoginPage} />
        <Route exact path={"/signup"} component={SignupPage} />
        <Route exact path={"/reset-password"} component={ForgotPassword} />
        <Route exact path={"/signup/:refCode"} component={SignupPage} />
        <Route exact path={"/term"} component={TermPage} />
        <Route exact path={"/privacy"} component={PrivacyPage} />
        <Route exact path={"/whitepaper"} component={WhitePaper} />
        <Route exact path={"/news"} component={NewsPage} />
        <Route exact path={"/news/:id/:url"} component={DetailPage} />
        {/* <Route exact path={"/token"} component={TokenPage} /> */}
        {/* <Route exact path={"/swap"} component={SwapPage} /> */}
        <Route exact path={"/"} component={HomePage} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
