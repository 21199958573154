import React from "react";
import whitepaper from "../../assets/img/whitepaper.jpg";

export default function WhitePaper() {
  return (
    <div className="whitepaper-page">
      <div className="coming-soon">
        <img src={whitepaper} alt="Whitepaper is coming soon" />
      </div>
    </div>
  );
}
