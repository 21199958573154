export const editorConfig = {
  height: 600,
  menubar: true,
  plugins: [
    // "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    // "visualblocks",
    "code",
    // "fullscreen",
    "insertdatetime",
    "media",
    "table",
    // "help",
    "wordcount",
  ],
  toolbar:
    "undo redo | cut copy paste | blocks | bold italic underline | forecolor backcolor | charmap | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | removeformat | preview | code",
  content_style: "body { font-family:Arial,sans-serif; font-size:14px }",
  menubar: "edit insert format table",
  entity_encoding: "raw",
  document_base_url: "/",
};
