import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import { APPLE_STORE, GOOGLE_PLAY_STORE } from "../../constant/constant";
import { calcBNBPrice, calcSell } from "../../function/web3";
import { axiosService } from "../../util/service";
import socket from "../../util/socket";
import Footer from "./Footer";
import Header from "./Header";
import PieChart from "./PieChart";
import Roadmap from "./Roadmap";

export default function HomeHeader() {
  // top button
  const [showButton, setShowButton] = useState(false);

  const [content, setContent] = useState([]);
  const [customData, setCustomData] = useState(undefined);
  const [team, setTeam] = useState([]);

  // const [hewePrice, setHewePrice] = useState(undefined);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  // const priceHEWE = async () => {
  //   const tokenAddres = "0x043124665b01C6E083bd43012B16926bE4845274";
  //   let bnbPrice = await calcBNBPrice();
  //   let tokens_to_sell = 1;
  //   let priceInBnb = (await calcSell(tokens_to_sell, tokenAddres)) / tokens_to_sell;

  //   const finalPrice = priceInBnb * bnbPrice;
  //   setHewePrice(finalPrice);
  // };

  useEffect(() => {
    scrollTop();

    getContent();

    getCustom();

    getTeam();

    socket.on("earn", (res) => {
      console.log(res);
      getCustom();
    });

    window.onscroll = () => {
      if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    // priceHEWE();

    // const thisInterval = setInterval(priceHEWE, 10000);

    // return () => {
    //   clearInterval(thisInterval);
    // };
  }, []);

  const getContent = async () => {
    try {
      let response = await axiosService.post("api/user/getContent");
      setContent(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTeam = async () => {
    try {
      const response = await axiosService.post("api/user/getTeam");
      setTeam(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustom = async () => {
    try {
      let response = await axiosService.post("api/moveToEarn/getCustom", {
        name: "EARN",
      });
      setCustomData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const renderTeamMember = () => {
    if (team.length == 0) {
      return <></>;
    }

    return team.map((member, index) => {
      return (
        <div className="item" key={index}>
          <img src={member.image} />
          <p>{member.title}</p>
          <span>{member.position}</span>
        </div>
      );
    });
  };

  return (
    <div className="home-page">
      {/* <div className="home-data-fixed">
        {hewePrice ? (
          <>
            <div className="data1">
              <span>1 HEWE</span>
              <div>= {hewePrice.toFixed(6)} USD</div>
            </div>

            <div className="data2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://pancakeswap.finance/swap?outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&inputCurrency=0x043124665b01C6E083bd43012B16926bE4845274"
              >
                BUY NOW
              </a>
            </div>
          </>
        ) : (
          <Spin />
        )}
      </div> */}

      {/* <div className="home-data-layer"></div> */}

      <Header
        isUsedOnHomePage={true}
        titleComponent={
          <div className="home-title">
            <div className="container">
              <div className="left">
                <h1>{content[0]?.title}</h1>
                <h3>{content[0]?.content}</h3>
                {window.innerWidth <= 992 ? (
                  <></>
                ) : (
                  <div className="download">
                    <a href={APPLE_STORE} target="_blank" rel="noopener noreferrer">
                      <img src="/img/appstore.svg" alt="Download on the Appstore" />
                    </a>
                    <a href={GOOGLE_PLAY_STORE} target="_blank" rel="noopener noreferrer">
                      <img src="/img/googleplay.svg" alt="Get in on Google Play" />
                    </a>
                  </div>
                )}
              </div>
              <div className="right">
                <img src="/img/03.gif" />
              </div>
            </div>
          </div>
        }
      />

      {customData && (
        <div className="home-data">
          <div className="container">
            <div className="item">
              <span className="title">REWARDED HEWE</span>
              <span className="content">{Number(customData).toFixed(3)}</span>
            </div>
            <div className="item">
              <span className="title">BURNED HEWE</span>
              <span className="content">{Number(customData).toFixed(3)}</span>
            </div>
          </div>
        </div>
      )}

      <div className="home-banner" id="our-journey">
        <div className="container">
          <div className="left">
            <div className="left2">
              <span>{content[1]?.title}</span>
            </div>
            <p className="left3">{content[1]?.content}</p>
            <div className="download">
              <a href={APPLE_STORE} target="_blank" rel="noopener noreferrer">
                <img src="/img/appstore.svg" alt="Download on the Appstore" />
              </a>
              <a href={GOOGLE_PLAY_STORE} target="_blank" rel="noopener noreferrer">
                <img src="/img/googleplay.svg" alt="Get in on Google Play" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="home-howtoplay" id="howtoplay">
        <div className="container">
          <div className="circle"></div>

          <h2 className="title">{content[2]?.title}</h2>

          <p>{content[2]?.content}</p>

          <div className="download">
            <a href={APPLE_STORE} target="_blank" rel="noopener noreferrer">
              <img src="/img/appstore.svg" alt="Download on the Appstore" />
            </a>

            <a href={GOOGLE_PLAY_STORE} target="_blank" rel="noopener noreferrer">
              <img src="/img/googleplay.svg" alt="Get in on Google Play" />
            </a>
          </div>
        </div>
      </div>

      <div className="home-piechart">
        <div className="container">
          <h2 className="title">Token Distribution</h2>

          <div id="pie-chart-wrapper">
            <div id="pie-chart-container">
              <div className="blur-item"></div>
              <div className="chart-item">
                <PieChart />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Roadmap />

      <div className="home-team" id="team">
        <div className="container">
          <h2 className="title">{content[3]?.title}</h2>

          <h3 className="subtitle">{content[3]?.content}</h3>

          <div className="list">{renderTeamMember()}</div>
        </div>
      </div>

      <div className="home-partner">
        <div className="container">
          <h2 className="title">HEWE Community</h2>

          <div className="list">
            <img
              src="/img/community/p1.png"
              style={{ width: window.innerWidth <= 576 ? 50 : 70 }}
              onClick={() => window.open("https://explorer.amchain.net/")}
            />

            <img
              src="/img/community/p2.png"
              style={{ width: window.innerWidth <= 576 ? 50 : 70 }}
              onClick={() => window.open("https://www.facebook.com/Hewetoken")}
            />

            <img
              src="/img/community/p3.png"
              style={{ width: window.innerWidth <= 576 ? 54 : 74 }}
              onClick={() => window.open("https://twitter.com/PierreN28036176")}
            />

            <img src="/img/community/p4.png" style={{ width: window.innerWidth <= 576 ? 75 : 85 }} />

            <img src="/img/community/p5.png" style={{ width: window.innerWidth <= 576 ? 60 : 80 }} />

            <img
              src="/img/community/p6.png"
              style={{ width: window.innerWidth <= 576 ? 50 : 70 }}
              onClick={() => window.open("https://www.linkedin.com/")}
            />
          </div>
        </div>
      </div>

      <Footer />

      {showButton ? (
        <div className="home-totop" onClick={scrollTop}>
          <i className="fa-solid fa-up-long"></i>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
