import { Button, Input, Tooltip, Radio } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { showAlert } from "../../function/showAlert";
import { axiosService } from "../../util/service.js";
import { useParams } from "react-router-dom";

export default function SignupPage({ history }) {
  const { refCode } = useParams();

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [wait, setWait] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      otp: "",
      password: "",
      password2: "",
      referral: refCode ? refCode : "hwf20022",
      gender: 1, // 1 male 2 female
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Required").email("Invalid email"),
      otp: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
      password2: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("password"), null], "Password not match"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const info = {
        referral: values.referral,
        email: values.email,
        password: values.password2,
        codeEmail: values.otp,
        gender: values.gender,
      };
      console.log(info);
      signup(info);
    },
  });

  const signup = async (info) => {
    setLoading(true);

    try {
      let response = await axiosService.post("api/user/signup", info);
      showAlert("success", response.data.message);
      history.replace("/login");
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const sendMail = async (email) => {
    setLoading2(true);

    try {
      let response = await axiosService.post("api/user/sendMailSignUp", { email });
      showAlert("success", response.data.message);
      setWait(true);
      setTimeout(() => {
        setWait(false);
      }, 60000);
    } catch (error) {
      console.log(error);
      showAlert("error", error.response.data.message);
    } finally {
      setLoading2(false);
    }
  };

  const handleSendMail = () => {
    if (formik.values.email === "") {
      showAlert("error", "Please enter your email");
    } else {
      sendMail(formik.values.email);
    }
  };

  const onChange = (e) => {
    formik.setFieldValue("gender", e.target.value);
  };

  if (localStorage.getItem("user") && localStorage.getItem("token")) {
    const x = JSON.parse(localStorage.getItem("user"));
    if (x.id == 1 || x.type == 1) {
      return <Redirect to="/admin" />;
    } else {
      return <Redirect to="/user" />;
    }
  }

  return (
    <div className="signup-page">
      <div className="signup-area">
        <div className="signup-logo">
          <img src="/img/logo.png" onClick={() => history.push("/home")} />
          <h2 className="title">Create account</h2>
        </div>

        <form id="signup-form">
          <div className="email-field field">
            <label htmlFor="email">Email</label>
            <Input type="email" id="email" value={formik.values.email} onChange={formik.handleChange} name="email" />
            {formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
          </div>

          <div className="otp-field field">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <label htmlFor="otp">Verification code</label>
              {!wait ? (
                <Button size="small" style={{ marginBottom: 4 }} onClick={handleSendMail} loading={loading2}>
                  Send email
                </Button>
              ) : (
                // waiting
                <Tooltip title="Please wait for 60 seconds to get other emails">
                  <Button size="small" style={{ marginBottom: 4 }} disabled>
                    Send email
                  </Button>
                </Tooltip>
              )}
            </div>
            <Input type="text" id="otp" value={formik.values.otp} onChange={formik.handleChange} name="otp" />
            {formik.errors.otp ? <div className="error">{formik.errors.otp}</div> : null}
          </div>

          <div className="password-field field">
            <label htmlFor="password">Password</label>
            <Input.Password
              type="password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              name="password"
            />
            {formik.errors.password ? <div className="error">{formik.errors.password}</div> : null}
          </div>

          <div className="password2-field field">
            <label htmlFor="password2">Confirm password</label>
            <Input.Password
              type="password"
              id="password2"
              value={formik.values.password2}
              onChange={formik.handleChange}
              name="password2"
            />
            {formik.errors.password2 ? <div className="error">{formik.errors.password2}</div> : null}
          </div>

          <div className="gender-field field">
            <label htmlFor="gender">Gender</label>
            <Radio.Group className="gender" value={formik.values.gender} onChange={onChange}>
              <Radio value={1}>Male</Radio>
              <Radio value={2}>Female</Radio>
            </Radio.Group>
          </div>

          <div className="referral-field field">
            <label htmlFor="referral">Referral code</label>
            <Input
              type="text"
              id="referral"
              value={formik.values.referral}
              onChange={formik.handleChange}
              name="referral"
              disabled
            />
          </div>

          <Button
            className="main"
            type="primary"
            style={{ width: "100%" }}
            size="large"
            onClick={formik.handleSubmit}
            loading={loading}
          >
            Create account
          </Button>

          <div className="link-to-login">
            Had an account?
            <span onClick={() => history.replace("/login")}> Login</span>
          </div>
        </form>
      </div>
    </div>
  );
}
