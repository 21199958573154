export const ROW_PER_TABLE = 10;
export const APPLE_STORE =
  "https://apps.apple.com/vn/app/hewe/id1645493575?l=vi";
export const GOOGLE_PLAY_STORE =
  "https://play.google.com/store/apps/details?id=com.heweapptoken";
export const API_KEY = "nmcwlqlyo6akstkjukrmnjs2bbfwvycscrz3kbgd5fdva8dt";

export const BANKS = [
  {
    fullName: "Ngân hàng TMCP Ngoại Thương Việt Nam",
    shortName: "NH TMCP Ngoai Thuong VN",
    code: "VCB",
    name: "VietcomBank",
    card: 970436,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1689567008/bank/VCB.png",
  },
  {
    fullName: "Ngân hàng TMCP Công Thương Việt Nam",
    shortName: "NH TMCP Cong Thuong VN",
    code: "CTG",
    name: "VietinBank",
    card: 970415,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1689567188/bank/CTG.png",
  },
  {
    fullName: "Ngân hàng TMCP Kỹ Thương Việt Nam",
    shortName: "NH TMCP Ky Thuong VN",
    code: "TCB",
    name: "Techcombank",
    card: 970407,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1689567296/bank/TCB.png",
  },
  {
    fullName: "Ngân hàng TMCP Đầu Tư và Phát Triển Việt Nam",
    shortName: "NH TMCP Dau Tu va Phat Trien VN",
    code: "BIDV",
    name: "BIDV",
    card: 970418,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1689567402/bank/BIDV.png",
  },
  {
    fullName: "Ngân hàng Nông Nghiệp và Phát Triển Nông Thôn Việt Nam",
    shortName: "NH Nong Nghiep va Phat Trien Nong Thon Viet Nam",
    code: "VARB",
    name: "AgriBank",
    card: 970405,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1689567504/bank/VARB.png",
  },
  {
    fullName: "Ngân hàng TMCP Quốc Dân",
    shortName: "Ngan hang Thuong Mai Co Phan Quoc Dan",
    code: "NVB",
    name: "Navibank",
    card: 970419,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1689568050/bank/NVB.png",
  },
  {
    fullName: "Ngân hàng TMCP Sài Gòn Thương Tín",
    shortName: "NH TMCP Sai Gon Thuong Tin",
    code: "STB",
    name: "Sacombank",
    card: 970403,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1689568127/bank/STB.png",
  },
  {
    fullName: "Ngân hàng TMCP Á Châu",
    shortName: "NH TMCP A Chau",
    code: "ACB",
    name: "ACB",
    card: 970416,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1689568198/bank/ACB.png",
  },
  {
    fullName: "Ngân hàng TMCP Quân Đội",
    shortName: "NH TMCP Quan Doi",
    code: "MB",
    name: "MBBank",
    card: 970422,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1689568279/bank/MB.png",
  },
  {
    fullName: "Ngân hàng TMCP Tiên Phong",
    shortName: "NH TMCP Tien Phong",
    code: "TPB",
    name: "TPBank",
    card: 970423,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1689568778/bank/TPB.png",
  },
  {
    fullName: "Ngân hàng TNHH MTV Shinhan Việt Nam",
    shortName: "NH TNHH MTV Shinhan VN",
    code: "SVB",
    name: "Shinhan Bank",
    card: 970424,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690167299/bank/SVB.png",
  },
  {
    fullName: "Ngân hàng TMCP Quốc Tế Việt Nam",
    shortName: "NH TMCP Quoc Te VN",
    code: "VIB",
    name: "VIB Bank",
    card: 970441,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690167373/bank/VIB.png",
  },
  {
    fullName: "Ngân hàng TMCP Việt Nam Thịnh Vượng",
    shortName: "NH TMCP Viet Nam Thinh Vuong",
    code: "VPB",
    name: "VPBank",
    card: 970432,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690167452/bank/VPB.png",
  },
  {
    fullName: "Ngân hàng TMCP Sài Gòn Hà Nội",
    shortName: "NH TMCP Sai Gon Ha Noi",
    code: "SHB",
    name: "SHB",
    card: 970443,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690167527/bank/SHB.png",
  },
  {
    fullName: "Ngân hàng TMCP Xuất Nhập Khẩu Việt Nam",
    shortName: "NH TMCP Xuat Nhap khau VN",
    code: "EIB",
    name: "Eximbank",
    card: 970431,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690167602/bank/EIB.png",
  },
  {
    fullName: "Ngân hàng TMCP Bảo Việt",
    shortName: "NH TMCP Bao Viet",
    code: "BVB",
    name: "BaoVietBank",
    card: 970438,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690167671/bank/BVB.png",
  },
  {
    fullName: "Ngân hàng TMCP Bản Việt",
    shortName: "NH TMCP Ban Viet",
    code: "VCCB",
    name: "VietcapitalBank",
    card: 970454,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690167734/bank/VCCB.png",
  },
  {
    fullName: "Ngân hàng TMCP Sài Gòn",
    shortName: "NH TMCP Sai Gon",
    code: "SCB",
    name: "SCB",
    card: 970429,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690167807/bank/SCB.png",
  },
  {
    fullName: "Ngân hàng Liên Doanh Việt Nga",
    shortName: "NH Lien Doanh Viet Nga",
    code: "VRB",
    name: "VietNam - Russia Bank",
    card: 970421,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690167871/bank/VRB.png",
  },
  {
    fullName: "Ngân hàng TMCP An Bình",
    shortName: "NH TMCP An Binh",
    code: "ABB",
    name: "ABBank",
    card: 970425,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690167943/bank/ABB.png",
  },
  {
    fullName: "Ngân hàng TMCP Đại Chúng Việt Nam",
    shortName: "NH TMCP Dai Chung VN",
    code: "PVCB",
    name: "PVCombank",
    card: 970412,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690168271/bank/PVCB.png",
  },
  {
    fullName: "Ngân hàng TM TNHH MTV Đại Dương",
    shortName: "NH TM TNHH MTV Dai Duong",
    code: "OJB",
    name: "OceanBank",
    card: 970414,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690168347/bank/OJB.png",
  },
  {
    fullName: "Ngân hàng TMCP Nam Á",
    shortName: "NH TMCP Nam A",
    code: "NAB",
    name: "NamA bank",
    card: 970428,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690168402/bank/NAB.png",
  },
  {
    fullName: "Ngân hàng TMCP Phát Triển TPHCM",
    shortName: "NH TMCP Phat Trien TP HCM",
    code: "HDB",
    name: "HDBank",
    card: "970437&970420",
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690168528/bank/HDB.png",
  },
  {
    fullName: "Ngân hàng TMCP Việt Nam Thương Tín",
    shortName: "NH TMCP Viet Nam Thuong Tin",
    code: "VB",
    name: "VietBank",
    card: 970433,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690168593/bank/VB.png",
  },
  {
    fullName: "Ngân hàng TNHH MTV Public Việt Nam",
    shortName: "NH TNHH MTV Public VN",
    code: "PBVN",
    name: "Public bank",
    card: 970439,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690168756/bank/PBVN.png",
  },
  {
    fullName: "Ngân hàng TNHH MTV Hongleong Việt Nam",
    shortName: "NH TNHH MTV Hongleong VN",
    code: "HLB",
    name: "Hongleong Bank",
    card: 970442,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690168859/bank/HLB.png",
  },
  {
    fullName: "Ngân hàng TMCP Xăng Dầu Petrolimax",
    shortName: "NH TMCP Xang Dau Petrolimex",
    code: "PGB",
    name: "PG Bank",
    card: 970430,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690168947/bank/PGB.png",
  },
  {
    fullName: "Ngân hàng Hợp Tác",
    shortName: "NH Hop Tac",
    code: "COB",
    name: "Co.op Bank",
    card: 970446,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169137/bank/COB.png",
  },
  {
    fullName: "Ngân hàng TNHH MTV CIMB Việt Nam",
    shortName: "NH TNHH MTV CIMB Viet Nam",
    code: "CIMB",
    name: "CIMB",
    card: 422589,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169204/bank/CIMB.png",
  },
  {
    fullName: "Ngân hàng TNHH Indovina",
    shortName: "NH TNHH Indovina",
    code: "IVB",
    name: "Indovina",
    card: 970434,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169271/bank/IVB.png",
  },
  {
    fullName: "Ngân hàngTMCP Đông Á",
    shortName: "NH TMCP Dong A",
    code: "DAB",
    name: "DongABank",
    card: 970406,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169331/bank/DAB.png",
  },
  {
    fullName: "Ngân hàng TNHH MTV Dầu Khí Toàn Cầu",
    shortName: "NH TM TNHH MTV Dau Khi Toan Cau",
    code: "GPB",
    name: "GPBank",
    card: 970408,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169397/bank/GPB.png",
  },
  {
    fullName: "Ngân hàng TMCP Bắc Á",
    shortName: "NH TMCP Bac A",
    code: "NASB",
    name: "BacABank",
    card: 970409,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169447/bank/NASB.png",
  },
  {
    fullName: "Ngân hàng TMCP Việt Á",
    shortName: "NH TMCP Viet A",
    code: "VAB",
    name: "VietABank",
    card: 970427,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169498/bank/VAB.png",
  },
  {
    fullName: "Ngân hàng TMCP Sài Gòn Công Thương",
    shortName: "NH TMCP Sai Gon Cong Thuong",
    code: "SGB",
    name: "SaigonBank",
    card: 970400,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169568/bank/SGB.png",
  },
  {
    fullName: "Ngân hàng TMCP Hàng Hải Việt Nam",
    shortName: "NH TMCP Hang Hai VN",
    code: "MSB",
    name: "Maritime Bank",
    card: 970426,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169615/bank/MSB.png",
  },
  {
    fullName: "Ngân hàng TMCP Bưu Điện Liên Việt",
    shortName: "NH TMCP Buu Dien Lien Viet",
    code: "LPB",
    name: "LienVietPostBank",
    card: 970449,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169683/bank/LPB.png",
  },
  {
    fullName: "Ngân hàng TMCP Kiên Long",
    shortName: "NH TMCP Kien Long",
    code: "KLB",
    name: "KienLongBank",
    card: 970452,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169737/bank/KLB.png",
  },
  {
    fullName: "Ngân hàng Công Nghiệp Hàn Quốc Chi Nhánh Hà Nội",
    shortName: "NH Cong Nghiep Han Quoc CN Ha Noi",
    code: "IBKHN",
    name: "IBK - Ha Noi",
    card: 970455,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169830/bank/IBKHN.png",
  },
  {
    fullName: "Ngân hàng Wooribank",
    shortName: "NH Wooribank",
    code: "WOO",
    name: "Woori bank",
    card: 970457,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169875/bank/WOO.png",
  },
  {
    fullName: "Ngân hàng TMCP Đông Nam Á",
    shortName: "NH TMCP Dong Nam A",
    code: "SEAB",
    name: "SeABank",
    card: 970440,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169928/bank/SEAB.png",
  },
  {
    fullName: "Ngân hàng TNHH MTV United Overseas Bank",
    shortName: "NH TNHH MTV United Overseas Bank",
    code: "UOB",
    name: "UOB",
    card: 970458,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690169993/bank/UOB.png",
  },
  {
    fullName: "Ngân hàng TMCP Phương Đông",
    shortName: "NH TMCP Phuong Dong",
    code: "OCB",
    name: "OCB",
    card: 970448,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690170047/bank/OCB.png",
  },
  {
    fullName: "Standard Chartered",
    shortName: "Standard Chartered",
    code: "STANDARD",
    name: "Standard Chartered",
    card: 970410,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690170220/bank/STANDARD.png",
  },
  {
    fullName: "Ngân hàng số CAKE by VPBank",
    shortName: "NH So CAKE by VPBank",
    code: "CAKE",
    name: "CAKE",
    card: 546034,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690170294/bank/CAKE.png",
  },
  {
    fullName: "Ngân hàng số Ubank by VPBank",
    shortName: "NH So Ubank by VPBank",
    code: "UBANK",
    name: "Ubank",
    card: 546035,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690170352/bank/UBANK.png",
  },
  {
    fullName: "Ngân hàng Nonghyup Chi Nhánh Hà Nội",
    shortName: "NH Nonghyup - Chi nhanh Ha Noi",
    code: "NonghyupBankHN",
    name: "Nonghyup Bank - HN",
    card: 801011,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690170505/bank/NonghyupBankHN.png",
  },
  {
    fullName: "Ngân hàng Kookmin Chi Nhánh Hà Nội",
    shortName: "NH Kookmin - Chi nhanh Ha Noi",
    code: "KBHN",
    name: "Kookmin - HN",
    card: 970462,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690170567/bank/KBHN.png",
  },
  {
    fullName: "Ngân hàng Kookmin Chi Nhánh TPHCM",
    shortName: "NH Kookmin - Chi nhanh TP. HCM",
    code: "KBHCM",
    name: "Kookmin - HCM",
    card: 970463,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690170566/bank/KBHCM.png",
  },
  {
    fullName: "Ngân hàng DBS Chi Nhánh TPHCM",
    shortName: "NH DBS - Chi nhanh TP. HCM",
    code: "DBSHCM",
    name: "DBS - HCM",
    card: 796500,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690170665/bank/DBSHCM.png",
  },
  {
    fullName: "Ngân hàng TM TNHH MTV Xây Dựng Việt Nam",
    shortName: "NH TM TNHH MTV Xay Dung Viet Nam",
    code: "CBBank",
    name: "CBBank",
    card: 970444,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690170767/bank/CBBank.png",
  },
  {
    fullName: "Ngân hàng Đại Chúng TNHH Kasikornbank Chi Nhánh TPHCM",
    shortName: "NH Dai chung TNHH Kasikornbank - Chi nhanh TP. HCM",
    code: "KBankHCM",
    name: "KBank - HCM",
    card: 668888,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690170851/bank/KBankHCM.png",
  },
  {
    fullName: "Ngân hàng TNHH MTV HSBC Việt Nam",
    shortName: "NH TNHH MTV HSBC Viet Nam",
    code: "HSBC",
    name: "HSBC",
    card: 458761,
    logo: "https://res.cloudinary.com/dszhslyjq/image/upload/v1690170908/bank/HSBC.png",
  },
];
