import React from "react";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div className="home-footer">
      <div className="container">
        <div className="item item1">
          <img src="/img/logo.svg" />
        </div>

        <div className="item item3">
          <NavLink to={"/privacy"}>Privacy Policy</NavLink>
          <NavLink to={"/term"}>Terms and Conditions</NavLink>
        </div>

        <div className="item item4">
          <a href="https://www.facebook.com/Hewetoken" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-facebook"></i>
          </a>
          <a href="https://twitter.com/PierreN28036176" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-twitter"></i>
          </a>
        </div>
      </div>
      {window.innerWidth <= 450 ? (
        <div className="container2">
          Copyright © 2024, Health &amp; Wealth. <br /> All rights reserved.
        </div>
      ) : (
        <div className="container2">Copyright © 2024, Health &amp; Wealth. All rights reserved.</div>
      )}
    </div>
  );
}
